import React from "react"
import { Helmet } from "react-helmet"
import { graphql, Link } from "gatsby"

import Layout from "../components/layout"
import { getGenderColor, GenderSymbol } from "../components/gender"
import { TrendGraph } from "../components/graphs"
import { FavoritesButton } from "../components/favorites"
import Ad from "../components/ad"

import { AttributeList } from "../components/attribute"
import { PageTeaser } from "../components/pageTeaser"

import styled from "styled-components"


const RankIcon = ({ rank = "", color }) => (
    <svg height={40} width={40}>
        <circle cx="50%" cy="50%" r={20}
            alignmentBaseline="middle"
            fill={color} />
        <text x="50%" y="50%"
            dominantBaseline="middle"
            textAnchor="middle"
            fill="white"
            fontSize={Math.floor(20 / Math.pow(toString(rank).length, 0.1))}>
            {rank}
        </text>
    </svg>
)

const NamePageFrame = styled.div`
    display: grid;
    grid-template-columns: 1fr;
    grid-template-areas: "intro" "namedata" "popularity" "similar";

    @media (min-width: 800px) {
        grid-template-columns: 4fr 1fr 4fr;
        grid-template-areas: "intro intro intro"  "namedata . popularity" "similar . popularity";
    }
`

export default ({ data }) => {
    const name = data.namesJson
    const similar_pages = data.combinations_pages.nodes
    const capitalize = (string) => (string.charAt(0).toUpperCase() + string.slice(1))

    const gender_color = getGenderColor(name.gender)

    const originList = name.attributes.filter(attribute => attribute.category === 'Herkunft')
    const meaningList = name.attributes.filter(attribute => attribute.category === 'Bedeutung')

    return (
        <Layout>
            <Helmet>
                <title>{name.name} - {name.title}</title>
                <meta name="description"
                    content={name.intro} />
                <meta name="keywords"
                    content={name.name + ", vorname, name"} />
                <link rel="canonical" href={'/' + name.id} />
            </Helmet>

            <NamePageFrame className="paper">
                <div className="section" style={{ gridArea: "intro" }}>
                    <h1 style={{
                        display: "flex",
                        gap: "10px",
                        alignItems: "center"
                    }}>
                        {name.name}
                        <GenderSymbol gender={name.gender} />
                        <FavoritesButton name={name} color={gender_color} size={28} />
                    </h1>
                    <p>{name.intro}</p>
                </div>
                <div style={{ gridArea: "namedata" }}>

                    {originList.length > 0 && (
                        <div className="section">
                            <h2>Herkunft</h2>
                            <AttributeList className="section" attributes={originList} />
                        </div>
                    )}

                    {meaningList.length > 0 && (
                        <div className="section">
                            <h2>Bedeutungen</h2>
                            <AttributeList className="section" attributes={meaningList} />
                        </div>
                    )}
                </div>

                <div style={{ gridArea: "similar" }}>

                    {name.similar_names.length > 0 &&
                        <div className="section">
                            <h2>Ähnlich klingende Namen</h2>
                            {name.similar_names.map(n => (
                                <Link key={n.id} to={"/" + n}>
                                    <span style={{ color: gender_color }}>{capitalize(n)}</span>
                                </Link>
                            )).reduce((acc, item) => [acc, ', ', item])}
                        </div>
                    }

                    {similar_pages.length > 0 &&
                        <div className="section">
                            <h2>Ähnliche Namen</h2>
                            {similar_pages.map(page => (
                                <PageTeaser key={page.url} page={page} color={gender_color} />
                            ))}
                        </div>
                    }
                </div>

                <div style={{ gridArea: "popularity" }}>
                    <div className="section">
                        <h2>Beliebtheit</h2>
                        <div style={{ display: "flex", alignItems: "center" }}>
                            <span>Platz</span>
                            <span style={{ margin: "0px 10px" }}><RankIcon rank={name.rank} color={gender_color} /> </span>
                            <span>nach Anzahl Geburten {
                                name.births.length > 0 ?
                                    "im Jahr " + name.births[name.births.length - 1].years
                                    : ""
                            }
                            </span>
                        </div>
                    </div>
                    {name.births.length > 0 &&
                        <div className="section" style={{ width: "100%" }}>
                            <h2>Anzahl Geburten •{name.name}•</h2>
                            <div style={{ width: "min(90%, 450px)" }}>
                                <TrendGraph
                                    births={name.births}
                                    color={gender_color}
                                    big={true}
                                />
                            </div>
                        </div>
                    }
                </div>
            </NamePageFrame>
            <Ad id="adnz_maxiboard_1" />
        </Layout>
    )
}

export const query = graphql`
    query ($name_id: String!, $combinations_page_urls: [String]){
        namesJson (id: {eq: $name_id }) {
            births {
                births
                years
            }
            attributes { 
                imagePath
                    associated_page_url
                    category
                    id
                    title
                    latest_births
                    births {
                        years
                        births
                    }
                    trend
                    rank
                    popularity
            }
            gender
            id
            title
            intro
            latest_births
            meaning
            name
            origin
            popularity
            syllables
            trend
            rank
            similar_names
        }
        combinations_pages: allPagesJson(filter: {url: {in: $combinations_page_urls}}) {
            nodes {
                url
                title
                number_of_names
                attributes {
                    imagePath
                    associated_page_url
                    category
                    id
                    latest_births
                    trend
                }
            }
        }
    }`