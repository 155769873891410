import React from 'react'
import { Link } from 'gatsby'
import { AttributeIcon } from './attribute'

export const PageTeaser = ({ page, color='#72a69a' }) => (
    <Link 
        to={"/"+page.url}
        style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
        }}>
        <div style={{flexShrink: 0}}>
            {page.attributes.map(attr => (
                <AttributeIcon 
                    attribute={attr} 
                    size={20} 
                    color={color} 
                    key={attr.id}
                />
                ))}
        </div>
        <span style={{margin: "5px"}}>{page.title}</span>
    </Link>
)