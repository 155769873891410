import React from "react"
import {CgGirl ,CgBoy, CgGenderMale, CgGenderFemale} from 'react-icons/cg'


export const getGenderColor = (gender) => {
    let color = "#5d5d5c"
    
    if (!gender.includes(',')) {
        if (gender.includes('Weiblich')) {
            color="#AC2896"
        } else {
            color="#3883BA"
        }
    }
    return color
}



export const GenderIcons = ({gender}) => (
    <span>
        {gender.includes('Männlich') && <CgBoy color="#3883BA"/>}
        {gender.includes('Weiblich') && <CgGirl color="#AC2896"/>}
   </span>
)

export const GenderSymbol = ({gender}) => (
    <span>
        {gender.includes('Männlich') && <CgGenderMale color="#3883BA"/>}
        {gender.includes('Weiblich') && <CgGenderFemale color="#AC2896"/>}
   </span>
)